import classes from './Header.module.css'

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg" >
                <div className="mx-auto">
                    <a className="navbar-brand">Digital Nomadz</a>
                </div>
            </nav>
        </header>
    );
}

export default Header;