import Header from "./Layout/Header";
import classes from './Landing.module.css';
import fbIcon from '../../assets/images/facebook.svg';
import instaIcon from '../../assets/images/instagram.svg';
import playIcon from '../../assets/images/play-fill.svg';
import pauseIcon from '../../assets/images/pause-fill.svg';
import jobsImage from '../../assets/images/jobs.jpg';
import locationImage from '../../assets/images/location.jpg';
import stayImage from '../../assets/images/stays.jpg';
import paymentImage from '../../assets/images/payment.jpg';
import themeSong from '../../assets/theme.mp3';
import ReactHowler from 'react-howler';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

let played = null;
let duration = null;
let percentage = 0;


const LandingPage = () => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [playPercentage, setPlayPercentage] = useState(percentage);
    let player = useRef(null);

    useEffect(() => {
        const timer = setInterval(() => {

            if (isPlaying && player) {
                console.log(player.seek());
                played = player.seek();
                duration = player.duration();
                percentage = played/duration * 100;
                console.log(percentage);
        setPlayPercentage(percentage);

            }
        }, 1000);
       

        return () => {
            clearInterval(timer);
          };
    }, [isPlaying, playPercentage]);



    return (
        <>
            <ReactHowler
                src={themeSong}
                playing={isPlaying}
                ref={(ref) => player = ref}

            />
            <div id="audioplayer" className="sticky-sm-top">
                <img id="pbutton" src={isPlaying ? pauseIcon : playIcon} alt="play-icon" onClick={() => isPlaying ? setIsPlaying(false) : setIsPlaying(true)} />
                <div id="timeline">
                    <div id="playhead" style={{ left: playPercentage + "%" }}></div>
                </div>
                <span className='slider-container'>
                </span>
            </div>

            <Header />
            <section>
                <main className={classes['main-background']}>
                    <div className={`container ${classes['full-height']}`} >
                        <div className={`row justify-content-center align-items-center  ${classes['height-100']}`}>
                            <div className={`col-xs-12 col-sm-10 col-md-12 col-lg-9 ${classes['welcome-background']}`}>
                                <h1 className="text-white text-center fw-bold heading-text">Work.Travel.Network</h1>
                                <h4 className="text-white text-center fw-bold heading-desc">A Experience crafted by Digital Nomads for Digital Nomads</h4>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
            <section className={classes['roadmap-background']}>
                <section className="steps">
                    <div className="container-fluid pt-4 pb-5">
                        <h2 className="text-center pt-2 pb-5">Roadmap to become a Digital Nomad</h2>
                        <p style={{ padding: '40px' }}></p>
                        <div className="row">
                            <div className="col-lg-12 py-5">
                                <div className="horizontal-timeline">
                                    <ul className="list-inline items">
                                        <li className="list-inline-item items-list">
                                            <div className="px-4">
                                                <div className="event-date badge step-buttons">Step 1</div>
                                                <h5 className="pt-2">Search For a Remote Job(Optional)</h5>
                                                <img src={jobsImage} className="timeline-image img-fluid"
                                                    alt="Responsive image" />
                                                <p className="text-steps">Search For a remote job which suits your work ethic and lifestyle</p>
                                                <p style={{ padding: "28px" }}></p>
                                                <div>
                                                    <a className="btn btn-primary btn-sm">Search Jobs</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-inline-item items-list">
                                            <div className="px-4">
                                                <div className="event-date badge step-buttons">Step 2</div>
                                                <h5 className="pt-2">Select Location</h5>
                                                <img src={locationImage} className="timeline-image img-fluid"
                                                    alt="Responsive image" />
                                                <p className="text-steps">Select the location you want to explore and experience as a digital nomad
                                                </p>
                                                <p style={{ padding: "28px" }}></p>
                                                <div>
                                                    <a className="btn btn-primary btn-sm">Choose Location</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-inline-item items-list">
                                            <div className="px-4">
                                                <div className="event-date badge step-buttons">Step 3</div>
                                                <h5 className="pt-2">Select Stay & Duration</h5>
                                                <img src={stayImage} className="timeline-image img-fluid"
                                                    alt="Responsive image" />
                                                <p className="text-steps">Select your stay according to your budget and your desired duration</p>
                                                <p style={{ padding: "28px" }}></p>
                                                <div>
                                                    <a className="btn btn-primary btn-sm">Choose Stay</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-inline-item items-list">
                                            <div className="px-4">
                                                <div className="event-date badge step-buttons">Step 4</div>
                                                <h5 className="pt-2">Pay & Confirm</h5>
                                                <img src={paymentImage} className="timeline-image img-fluid"
                                                    alt="Responsive image" />
                                                <p className="text-steps">Pay the full amount upfront or pay a minimum amount in advance to start your Journey !
                                                </p>
                                                <small className="text-sm text-steps">**Note: As an introductory offer we will be having no subscriptions on this platform but only charges required for your smooth stay and experience</small>
                                                <div>
                                                    <a className="btn btn-primary btn-sm">Payment Options</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-fluid overview">
                        <div className="row align-items-center text-center text-white">
                            <div className={`col-sm-12 col-md-4 align-self-center ${classes['locations']}`}>
                                <h1 className={classes['overview-text']}>Locations</h1>
                            </div>
                            <div className={`col-sm-12 col-md-4 align-self-center ${classes['stays']}`}>
                                <h1 className={classes['overview-text']}>Stays</h1>
                            </div>
                            <div className={`col-sm-12 col-md-4 align-self-center ${classes['jobs']}`}>
                                <h1 className={classes['overview-text']}>Jobs</h1>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="footer">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <span>Follow Us On</span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <img src={fbIcon} alt="fb-icon" />
                        <img src={instaIcon} alt="insta-icon" />
                    </div>
                    <div className="row text-center">
                        <p>&#169; Digital Nomadz Copyright 2022</p>
                    </div>
                </section>
            </section>
        </>
    );
}

export default LandingPage;